// Import everything from autoload folder
import './autoload/_bootstrap.js'; import './autoload/ada-compliance.js'; // eslint-disable-line

// Import local dependencies
//import './plugins/lazyload';
import './plugins/modernizr.min';
import LazyLoad from 'vanilla-lazyload';
import barba from '@barba/core';
import 'slick-carousel';
import 'jquery-match-height';
import 'jquery-mask-plugin';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
import ScrollOut from 'scroll-out';
/* global ajax_object */

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * BarbsJS init
   */
  if ($('body').attr('data-barba')) {
    barba.init({
      timeout: 30000,
      prefetchIgnore: true,
      transitions: [
        {
          name: 'basic',
          enter: function enter() {
            $(() => {
              // Make match height.
              $('.js-match-height').matchHeight({
                byRow: true,
                property: 'height',
                target: null,
                remove: false,
              });
            });

            // Init LazyLoad (for ignore .no-lazyload).
            let lazyLoadInstance = new LazyLoad({
              elements_selector: 'img[data-lazy-src],.pre-lazyload',
              data_src: 'lazy-src',
              data_srcset: 'lazy-srcset',
              data_sizes: 'lazy-sizes',
              skip_invisible: false,
              class_loading: 'lazyloading',
              class_loaded: 'lazyloaded',
            });

            // Add tracking on adding any new nodes to body to update lazyload for the new images (AJAX for example).
            window.addEventListener(
              'LazyLoad::Initialized',
              function () {
                // Get the instance and puts it in the lazyLoadInstance variable.
                if (window.MutationObserver) {
                  let observer = new MutationObserver(function (mutations) {
                    mutations.forEach(function (mutation) {
                      mutation.addedNodes.forEach(function (node) {
                        if (typeof node.getElementsByTagName !== 'function') {
                          return;
                        }
                        let imgs = node.getElementsByTagName('img');
                        if (0 === imgs.length) {
                          return;
                        }
                        lazyLoadInstance.update();
                      });
                    });
                  });
                  let b = document.getElementsByTagName('body')[0];
                  let config = { childList: true, subtree: true };
                  observer.observe(b, config);
                }
              },
              false
            );

            // Update LazyLoad images before Slide change.
            $('.slick-slider').on('beforeChange', function () {
              lazyLoadInstance.update();
            });
          },
          beforeEnter: function beforeEnter() {
            $('html, body').animate(
              {
                scrollTop: 0,
              },
              500
            );

            if (!$('.js-navbar-toggler').hasClass('collapsed')) {
              $('.js-navbar-toggler').trigger('click');
            }
          },
          after: function after() {
            let pageEdit = $(document).find('#bwp-main').data('edit');
            if (pageEdit) {
              $(document).find('#wp-admin-bar-edit > a').attr('href', pageEdit);
            }
            if ($('.form-wrapper').length) {
              let formID = $('.form-wrapper').attr('data-form-id');
              let formRef = $('.form-wrapper');

              $.ajax({
                url: ajax_object.ajax_url,
                type: 'GET',
                data: {
                  form_id: formID,
                  action: 'get_form',
                },
              }).done(function (response) {
                $('.form-wrapper').html(response);

                setTimeout(function () {
                  formRef.find('script').each((index, el) => {
                    window.eval($(el).text());
                  });
                }, 1500);
              });
            }

            /*  Make elements equal height */
            $('.matchHeight').matchHeight();

            /* ScrollOut init */
            ScrollOut({
              cssProps: {
                visibleY: true,
                viewportY: true,
                scrollPercentY: true,
              },
            });

            // Update elements
            videoAutoplay();
            resizeVideo();
            slickInit();

            // On load *only* if we have anchor on the url
            if (window.location.hash) {
              // smooth scroll to the anchor id
              $('html, body').animate(
                {
                  scrollTop: $(window.location.hash).offset().top - 90 + 'px',
                },
                750,
                'swing'
              );
            }

            // Override bootstrap submenu toggle
            $('a.dropdown-toggle').on('click keyup', function (e) {
              e.preventDefault();
              e.stopPropagation();
              let $this = $(e.target);
              bootstrapSubmenuToggle($this);
            });
          },
        },
      ],
      prevent: ({ el }) =>
        el.classList &&
        (el.classList.contains('ab-item') ||
          el.classList.contains('fancybox-image') ||
          el.hasAttribute('data-fancybox')),
    });
  }
  /**
   * Detect element appearance in viewport
   */
  ScrollOut({
    cssProps: {
      visibleY: true,
      viewportY: true,
      scrollPercentY: true,
    },
    onShown: function (element) {
      if ($(element).is('.ease-order')) {
        $(element)
          .find('.ease-order__item')
          .each(function (i) {
            let $this = $(this);
            $(this).attr('data-scroll', '');
            window.setTimeout(function () {
              $this.attr('data-scroll', 'in');
            }, 300 * i);
          });
      }
    },
  });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        // TODO: replace with bootstrap toggle
        // $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  $(document).on('click', '.header .navbar-toggler', function () {
    $('header.header').toggleClass('is-open');
  });

  // Override bootstrap submenu toggle
  $('a.dropdown-toggle').on('click keyup', function (e) {
    e.preventDefault();
    e.stopPropagation();
    let $this = $(e.target);

    bootstrapSubmenuToggle($this);
  });

  // Run functions on ready
  videoAutoplay();
  resizeVideo();
  listLinks();
  boxMore();
  boxService();
  blogFilter();
  blogLoadMore();
  trainingFilter();
  trainingLoadMore();
  openLightboxForm();
  slickInit();
  addIntroClass();
}); // End of Document ready

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here
  slickClientPlay();
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here
  $('.js-slider-mobile').each(function () {
    $(this).slick('resize');
  });

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
let lastScrollTop = 0;
$(window).on('scroll', function () {
  // jQuery code goes here
  let st = $(this).scrollTop();
  $('html')
    .toggleClass('scrolling-top', st < lastScrollTop)
    .toggleClass('scrolling-bottom', st > lastScrollTop);
  $('html').toggleClass(
    'scrolling-footer',
    st >= $(document).height() - $(window).height() * 2
  );
  if (st === 0) $('html').removeClass('scrolling-top scrolling-bottom');
  lastScrollTop = st;

  // Hero video play
  $(document)
    .find('.js-hero-video:not(.js-video-autoplay)')
    .each(function (i, el) {
      if (st === $(el).offset().top) {
        $(el).addClass('is-playing')[0].play();
      } else {
        $(el)[0].pause();
      }
    });
});

// Anchor click scrolling
$(document).on('click', 'a[href^="#"]', function (e) {
  if (
    $(this).attr('href') != '#' &&
    !$(this).hasClass('fancybox') &&
    $(this).attr('role') != 'tab'
  ) {
    e.preventDefault();
    let $this = $(this);
    if ($($this.attr('href')).length) {
      $('html, body').animate(
        {
          scrollTop: $($this.attr('href')).offset().top - 80 + 'px',
        },
        750,
        'swing'
      );
    }
  }
});

function videoAutoplay() {
  $(document)
    .find('.js-video-autoplay')
    .each(function (i, el) {
      $(el).addClass('is-playing')[0].play();
    });
}

// Slick sliders
function slickInit() {
  $(document)
    .find('.js-slider-mobile')
    .each(function () {
      let sliderType = $(this).data('type'),
        sliderSettings = {
          dots: false,
          arrows: false,
          infinite: false,
          speed: 1000,
          slidesToShow: 1,
          slidesToScroll: 1,
          mobileFirst: true,
          centerMode: true,
          responsive: [
            {
              breakpoint: 769,
              settings: 'unslick',
            },
          ],
        };

      // Slider additional settings
      if (sliderType === 'primary') {
        //sliderSettings.slidesToShow = 1.1;
      } else if (sliderType === 'secondary') {
        sliderSettings.dots = true;
      }

      $(this).slick(sliderSettings);
    });

  $(document)
    .find('.js-blog-slider')
    .each(function () {
      let sliderSettings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      };

      $(this).slick(sliderSettings);
    });

  $(document)
    .find('.js-client-slider')
    .each(function () {
      let $this = $(this);
      let sliderSettings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 300,
        autoplay: false,
        autoplaySpeed: 5000,
        vertical: true,
        lazyLoad: 'progressive',
        verticalSwiping: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      };

      $this.slick(sliderSettings);
    });
}

function slickClientPlay() {
  $(document)
    .find('.js-client-slider')
    .each(function (index) {
      let $this = $(this);
      setTimeout(() => {
        $this.slick('slickPlay');
      }, (index + 1) * 100);
    });
}

// Flexible list links
function listLinks() {
  $(document).on('mouseover', '.js-list-link', function (e) {
    e.preventDefault();
    let imageId = $(this).data('image');
    if (imageId) {
      $(this)
        .closest('.js-list')
        .find('.js-list-image')
        .removeClass('is-active');
      $(document)
        .find('#' + imageId)
        .addClass('is-active');
    }
  });
}

// Flexible Comparer more
function boxMore() {
  $(document).on('click', '.js-box-more', function (e) {
    e.preventDefault();

    $(this)
      .toggleClass('is-active')
      .closest('.box-content')
      .toggleClass('is-open')
      .find('.js-box-hidden')
      .slideToggle('slow');
  });
}

// Flexible Services
function boxService() {
  $(document).on('mouseenter mouseleave', '.js-box-service', function () {
    let $this = $(this),
      $boxBackground = $this
        .closest('.flex_services')
        .find('.js-box-background');

    $boxBackground.toggleClass('is-active');

    if ($this.data('image')) {
      $boxBackground.css(
        'background-image',
        'url(' + $this.data('image') + ')'
      );
    }
  });
}

function blogFilter() {
  $(document).on('click', '.js-category', function (e) {
    e.preventDefault();

    let $this = $(this),
      $blogSection = $this.closest('.flex_blog'),
      $blogContainer = $blogSection.find('.js-blog-container'),
      $blogLoadMore = $(document).find('.js-load-more'),
      $allBlogContainers = $(document).find('.js-blog-container');
    $allBlogContainers.addClass('searching');

    $blogSection.find('.js-category').removeClass('is-active');
    $this.addClass('is-active');
    $this.closest('.collapse').collapse('hide');
    $blogSection.find('.js-category-active').text($this.text());

    // AJAX Search
    $.post(ajax_object.ajax_url, {
      action: 'blog_articles',
      category: $this.data('category'),
      number: $blogContainer.data('number'),
      type: $blogContainer.data('type'),
    }).done(function (response) {
      $allBlogContainers.empty().removeClass('searching');
      $blogContainer.html(response.html);
      $blogLoadMore.toggleClass(
        'd-none',
        $blogContainer.data('number') >= response.count
      );
    });
  });
}

function blogLoadMore() {
  $(document).on('click', '.js-load-more:not(.is-loading)', function (e) {
    e.preventDefault();

    let $this = $(this),
      $blogSection = $this.closest('.flex_blog'),
      $blogContainer = $blogSection.find('.js-blog-container'),
      $allBlogContainers = $(document).find('.js-blog-container'),
      $blogCategory = $(document).find('.js-category.is-active'),
      $blogArticles = $(document).find('article.type-post, article.type-cases');
    let articleIds = [];

    $allBlogContainers.addClass('searching');

    $blogSection.find('.js-category').removeClass('is-active');
    $this.addClass('is-loading');

    if ($blogArticles.length) {
      $blogArticles.each((index, el) => {
        articleIds.push($(el).data('id'));
      });
    }

    // AJAX Search
    $.post(ajax_object.ajax_url, {
      action: 'blog_articles',
      category: $blogCategory.data('category'),
      number: $blogContainer.data('number'),
      type: $blogContainer.data('type'),
      articles: JSON.stringify(articleIds),
    }).done(function (response) {
      if (response.load_more) {
        $blogContainer.append(response.html);
      } else {
        $blogContainer.html(response.html);
      }
      $allBlogContainers.removeClass('searching');
      $this
        .removeClass('is-loading')
        .toggleClass('d-none', $blogContainer.data('number') >= response.count);
    });
  });
}

function trainingFilter() {
  $(document).on('click', '.js-training-category', function (e) {
    e.preventDefault();

    let $this = $(this),
      $trainingSection = $this.closest('.flex_training'),
      $trainingContainer = $trainingSection.find('.js-training-container'),
      $trainingLoadMore = $(document).find('.js-load-training'),
      $allTrainingContainers = $(document).find('.js-training-container');
    $allTrainingContainers.addClass('searching');

    $trainingSection.find('.js-training-category').removeClass('is-active');
    $this.addClass('is-active');
    $this.closest('.collapse').collapse('hide');
    $trainingSection.find('.js-training-category-active').text($this.text());

    // AJAX Search
    $.post(ajax_object.ajax_url, {
      action: 'training_articles',
      category: $this.data('category'),
      number: $trainingContainer.data('number'),
    }).done(function (response) {
      $allTrainingContainers.empty().removeClass('searching');
      $trainingContainer.html(response.html);
      $trainingLoadMore.toggleClass(
        'd-none',
        $trainingContainer.data('number') >= response.count
      );
    });
  });
}

function trainingLoadMore() {
  $(document).on('click', '.js-load-training:not(.is-loading)', function (e) {
    e.preventDefault();

    let $this = $(this),
      $trainingSection = $this.closest('.flex_training'),
      $trainingContainer = $trainingSection.find('.js-training-container'),
      $allTrainingContainers = $(document).find('.js-training-container'),
      $trainingCategory = $(document).find('.js-training-category.is-active'),
      $trainingArticles = $(document).find('article.type-training');
    let articleIds = [];

    $allTrainingContainers.addClass('searching');

    $trainingSection.find('.js-training-category').removeClass('is-active');
    $this.addClass('is-loading');

    if ($trainingArticles.length) {
      $trainingArticles.each((index, el) => {
        articleIds.push($(el).data('id'));
      });
    }

    // AJAX Search
    $.post(ajax_object.ajax_url, {
      action: 'training_articles',
      category: $trainingCategory.data('category'),
      number: $trainingContainer.data('number'),
      articles: JSON.stringify(articleIds),
    }).done(function (response) {
      if (response.load_more) {
        $trainingContainer.append(response.html);
      } else {
        $trainingContainer.html(response.html);
      }
      $allTrainingContainers.removeClass('searching');
      $this
        .removeClass('is-loading')
        .toggleClass(
          'd-none',
          $trainingContainer.data('number') >= response.count
        );
    });
  });
}

function openLightboxForm() {
  $(document).on('click', 'a[href="#lightbox"]', function (e) {
    e.preventDefault();
    $(document, '#lightbox')
      .find('.decor_hover')
      .removeClass('decor_hover--active');
    $.fancybox.open($('#lightbox_form'), {
      type: 'inline',
      afterShow: function () {
        $(document, '#lightbox')
          .find('.decor_hover')
          .addClass('decor_hover--active');
      },
    });
  });
}

function addIntroClass() {
  if ($('body').hasClass('home')) {
    $('body').addClass('start-intro-video');
  }
}

function bootstrapSubmenuToggle($toggle) {
  if ($toggle === undefined || !$toggle.length) return false;

  if ($toggle.hasClass('js-dropdown-toggle')) {
    $toggle
      .closest('.dropdown')
      .toggleClass('show')
      .find('.dropdown-menu')
      .toggleClass('show');
    $toggle
      .toggleClass('is-active')
      .closest('.dropdown-toggle')
      .attr('aria-expanded', (i, attr) => (attr === 'true' ? 'false' : 'true'));
  } else if ($toggle.closest('a').attr('href')) {
    if ($('body').attr('data-barba')) {
      barba.go($toggle.closest('a').attr('href'));
    } else {
      location.href = $toggle.closest('a').attr('href');
    }
  }
}

$(document).on('ready', function () {
  let lastScrollTop = 0;
  $(window).on('scroll', function () {
    if (window.innerWidth > 991) {
      var currentScroll = $(this).scrollTop();
      if (currentScroll > lastScrollTop) {
        // Scrolling down
        $('#navbar').css('top', '-100px');
      } else {
        // Scrolling up
        $('#navbar').css('top', '0');
      }
      lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
    }
  });
});
